<template>
  <base-section
    id="why-cleaning-robot"
    class="pb-5 pt-0"
  >
    <v-container>
      <br>
      <br>
      <base-section-heading title="WHY CLEAN SOLAR PANELS?">
      </base-section-heading>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWhyCleaningRobot',

    data: () => ({
      classConf: '',
      cards: [
        {
          title: 'Maximize Efficiency',
          subtitle: '',
          text: 'Dust, dirt, and debris can reduce solar panel efficiency by up to 30%, significantly impacting energy output.',
          callout: '',
          icon: 'mdi-chart-areaspline',
        },
        {
          title: 'Maintain Performance',
          subtitle: '',
          text: 'Regular cleaning is essential to keep solar panels operating at peak performance and maximize your investment.',
          callout: '',
          icon: 'mdi-trophy',
        },
        {
          title: 'Extend Lifespan',
          subtitle: '',
          text: 'Proper maintenance, including cleaning, can prolong the useful life of solar panels, providing long-term benefits.',
          callout: '',
          icon: 'mdi-progress-wrench',
        },
      ],
    }),
  }
</script>
